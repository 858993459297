<template>
    <div>
        <v-card >
            <v-card-title class="blue-grey white--text">USUARIO</v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12" md="5">
                            <v-row class="mt-5">
                                <v-col cols="8" md="5" class="form-space">
                                    <v-text-field class='text-caption' v-model="usuario" label="Usuario" outlined dense :disabled="disabledUsuario" :filled="filledUsuario" required></v-text-field>
                                </v-col>
                                <v-col cols="4" md="2" class="form-space">
                                    <v-btn @click="verUsuario(usuario)" class="mt-1" small color="teal" dark rounded><v-icon small>search</v-icon></v-btn>
                                </v-col>
                                <v-col cols="12" md="5" class="form-space">
                                    <v-alert class='text-caption' v-if="existe=='si'" dense text type="success">
                                        Existe !
                                    </v-alert>
                                    <v-alert class='text-caption' v-else-if="existe=='no'" dense text type="warning">
                                        Nuevo !
                                    </v-alert>
                                    <v-alert class='text-caption' v-else dense text type="info">
                                        ID Usuario
                                    </v-alert>
                                </v-col>
                                <v-col cols="12" md="4" class="form-space">
                                    <v-select class='text-caption' v-on:change="openDialog(sucursal)" v-model="sucursal" :items="itemsucursal" item-text="name" item-value="code" label="Sucursal" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>                                
                                </v-col>
                                <v-col cols="12" md="8" class="form-space">
                                    <v-text-field class='text-caption' v-model="nombre" label="Nombre" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="7" class="form-space">
                                    <v-text-field class='text-caption' v-model="email" label="Email" outlined dense clearable :disabled="disabledDatos" :filled="filledDatos"></v-text-field>
                                </v-col>
                                <v-col cols="6" md="5" class="form-space">
                                        <v-row class="justify-center">
                                            <span class='text-caption'>Comisiones</span>
                                        </v-row>
                                        <v-row class="justify-center">
                                            <v-btn fab x-small dark @click="dialogComision=true" color="indigo"><v-icon>add</v-icon></v-btn>
                                        </v-row>
                                </v-col>
                                <v-col cols="6" md="3" class="form-space">
                                    <v-select class='text-caption' v-model="estado" :items="estados" label="Estado" dense outlined :disabled="disabledDatos" :filled="filledDatos"></v-select>
                                </v-col>
                                <v-col cols="6" md="9" class="form-space">
                                </v-col>
                                <v-col cols="12" md="4" class="form-space">
                                    <v-switch style="margin-top:0px" v-model="Ecommerce" label="Ecommerce"></v-switch>
                                </v-col>
                                <v-col cols="12" md="4" class="form-space">
                                    <v-switch style="margin-top:0px" v-model="Importa" label="Importación"></v-switch>
                                </v-col>
                                <v-col cols="12" md="4" class="form-space">
                                    <v-switch style="margin-top:0px" v-model="Reporte" label="Full Report"></v-switch>
                                </v-col>
                                <v-col cols="12" md="4" class="form-space">
                                    <v-switch style="margin-top:0px" v-model="Integra" label="Integra"></v-switch>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" md="7" class="form-space">
                            <v-row class="mt-1">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">DashBord</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_1_1"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Productos</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_2_1" label="Crear"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_2_2" label="Web"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Compra / Cotización</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_1" label="Compra"></v-switch>
                                </v-col>
                                 <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_3" label="Cotiza"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Venta / Devolución</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_2" label="Venta"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_3_4" label="Devolución"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Flujo Caja</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_7_1" label="Caja"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_7_2" label="Flujo"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_7_3" label="Pagos"></v-switch>
                                </v-col>
                            </v-row>                            
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Inventario</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_4_1" label="Stock"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_4_2" label="Ingreso"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_4_3" label="Egreso"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Credito Cobranza</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_8_1" label="Pago Cliente"></v-switch>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-switch v-model="Permiso_8_2" label="Pago Proveedor"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Estadistica Gerencia</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_5_1"></v-switch>
                                </v-col>
                            </v-row>
                            <v-row style="margin-top:-55px">                           
                                <v-col cols="12" md="3">
                                    <h5 class="mt-5 primary--text">Configuracion</h5>
                                    <v-divider></v-divider>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_6_1" label="Proveedor"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_6_2" label="Cliente"></v-switch>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-switch v-model="Permiso_6_3" label="Usuario"></v-switch>
                                </v-col>
                            </v-row>
                            <div class="float-end">
                                <v-btn  @click="newUsuario()" class="mr-5" small color="teal" dark rounded><v-icon small>add_circle</v-icon><span class='text-btn'>Nuevo</span></v-btn>
                                <v-btn @click="saveUsuario()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                            </div>                            
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions style="margin-top:-30px">
            </v-card-actions>
        </v-card>
        <!-- DIALOGO COMISIONES -->
        <v-dialog v-model="dialogComision" transition="dialog-bottom-transition" max-width="800" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Comisiones del Usuario
                <v-spacer></v-spacer>
                <v-btn @click="dialogComision=false" small fab text><v-icon>close</v-icon></v-btn>
            </v-toolbar>
            <v-card-text class="mt-5">
                <strong>Comision General (%)</strong>
                <v-row class="mt-5">
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-2">Comision</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="comiGeneral" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-2">Tope Descuento General</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="descGeneral" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-1">Comision Gerente</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-switch class="mt-n1" v-model="gerente" label=""></v-switch>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-2">Tope Descuento Comisionable</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="descComision" outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <strong>Comisiones Adicionales (%)</strong>
                <v-divider></v-divider>
                <span class="text-caption">Formas de Pago</span>
                <v-row class="mt-5">
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-2">Tarjeta de Credito/Debito</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="comision_01" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-2">Cheque</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="comision_02" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                         <p class="text-end text-caption mt-2">Contado al Dia con Descuento</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="comision_03" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-2">Credito con Descuento</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="comision_04" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                         <p class="text-end text-caption mt-2">Contado al Dia sin Descuento</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="comision_05" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="8" md="4" class="form-space">
                        <p class="text-end text-caption mt-2">Credito sin Descuento</p>
                    </v-col>
                    <v-col cols="4" md="2" class="form-space">
                        <v-text-field @keypress="onlyNumber" class='text-caption' v-model="comision_06" outlined dense></v-text-field>
                    </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                <v-col cols="12" md="12">
                    <div class="float-end mt-2 mr-2">
                    <v-btn @click="addComision()" small fab dark color="indigo"><v-icon small>save</v-icon></v-btn>
                    </div>
                </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Dialogo Sucursal -->
        <v-dialog v-model="dialogSucursal" transition="dialog-bottom-transition" max-width="600" persistent>
            <v-card>
            <v-toolbar color="primary" dark>Nueva Sucursal</v-toolbar>
            <v-card-text class="mt-5">
                <v-text-field class='text-caption' v-model="newSucursal" label="Nombre Sucursal" :disabled="disabledSucursal" :filled="filledSucursal" outlined dense required></v-text-field>
                <v-row>
                    <v-col cols="12" md="6">
                        <div v-if="alertaSucursal!='si'">
                            <v-btn @click="newSucursales()" small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                        <div v-else>
                            <v-btn small color="teal" dark rounded><v-icon small>save</v-icon><span class='text-btn'>Grabar</span></v-btn>
                        </div>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-alert class='text-caption' v-if="alertaSucursal=='si'" dense text type="success">
                            Sucursal Creada con Exito !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else-if="alertaSucursal=='no'" dense text type="error">
                            No ha Ingresado Sucursal !!!
                        </v-alert>
                        <v-alert class='text-caption' v-else dense text type="info">
                            Dato Obligatorio !!!
                        </v-alert>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="dialogSucursal=false" color="indigo">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <snack :Msg="Msg" :color="color" :msgbox="msgbox"/>
        <dialogo :loading="loading"  />
    </div>
</template>
<style>
.form-space {
    margin-top:-35px;
}
.text-space {
    margin-top:-25px;
}
</style>
<script>
import snack from '@/components/snack.vue';
import dialogo from '@/components/dialogo.vue';
import axios from 'axios';
export default {
    name:'Usuario',
    components:{dialogo, snack},
    props:['usu','contrato'],
    data(){
        return{
            //contrato:'123',
            loading:false,
            msgbox:false,
            color:'',
            Msg:'',
            existe:'',            
            disabledUsuario:false,
            filledUsuario:false,
            disabledDatos:true,
            filledDatos:true,
            usuario:'',
            nombre:'',
            email:'',
            comision:0,
            Permiso_1_1:false,
            Permiso_2_1:false,
            Permiso_2_2:false,
            Permiso_3_1:false,
            Permiso_3_2:false,
            Permiso_3_3:false,
            Permiso_3_4:false,
            Permiso_4_1:false,
            Permiso_4_2:false,
            Permiso_4_3:false,
            Permiso_5_1:false,
            Permiso_6_1:false,
            Permiso_6_2:false,
            Permiso_6_3:false,
            Permiso_7_1:false,
            Permiso_7_2:false,
            Permiso_7_3:false,
            Permiso_8_1:false,
            Permiso_8_2:false,
            nPermiso_1_1:0,
            nPermiso_2_1:0,
            nPermiso_2_2:0,
            nPermiso_3_1:0,
            nPermiso_3_2:0,
            nPermiso_3_3:0,
            nPermiso_3_4:0,
            nPermiso_4_1:0,
            nPermiso_4_2:0,
            nPermiso_4_3:0,
            nPermiso_5_1:0,
            nPermiso_6_1:0,
            nPermiso_6_2:0,
            nPermiso_6_3:0,
            nPermiso_7_1:0,
            nPermiso_7_2:0,
            nPermiso_7_3:0,
            nPermiso_8_1:0,
            nPermiso_8_2:0,
            Reporte:false,
            Ecommerce:false,
            Importa:false,
            nReporte:0,
            nEcommerce:0,
            nImporta:0,
            alertaSucursal:'',
            disabledSucursal:false,
            filledSucursal:false,
            newSucursal:'',
            dialogSucursal:false,
            itemsucursal:[],
            sucursal:0,
            estados:['V','N'],
            estado:'V',
            //comision y descuento
            dialogComision:false,            
            comiGeneral:'',
            descGeneral:'',
            descComision:'',
            comision_01:'',
            comision_02:'',
            comision_03:'',
            comision_04:'',
            comision_05:'',
            comision_06:'',
            gerente:false,
            Integra:false,
            nIntegra:0,
        }
    },
    mounted(){
        this.buscarSucursal()
    },
    methods:{
        async addComision(){
            if (this.usuario.trim()!='' && this.existe=='si'){
                var comiGerente='no';
                if(this.gerente==true){ comiGerente='si' };
                var parametros={
                    'contrato': this.contrato,
                    'usuario': this.usuario,
                    'comision': this.comiGeneral,
                    'desgeneral': this.descGeneral,
                    'descomision': this.descComision,
                    'tarjetas': this.comision_01,
                    'cheque': this.comision_02,
                    'contadocondes': this.comision_03,
                    'creditocondes': this.comision_04,
                    'contadosindes': this.comision_05,
                    'creditosindes': this.comision_06,
                    'gerente':comiGerente
                };
                try {
                    const response = await axios.delete('https://www.transervi.cl/Api_TranServi/public/api/inv/comisiones/delete/'+this.contrato+'/'+this.usuario);
                    const datos=response.data;
                    if (datos!='error'){
                        const response1 = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/comisiones/nueva',parametros);
                        const datos1=response1.data;
                        if (datos1=='si'){
                            this.Msg='Datos Grabados'
                            this.msgbox=true;
                            this.color='green';
                            this.showSnack();
                        } else {
                            this.Msg='Datos alterados, intente nuevamente'
                            this.msgbox=true;
                            this.color='red';
                            this.showSnack();
                        }
                    } else {
                        this.Msg='Sin cambios, intente mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                    }                 
                } catch (error) {
                    console.log(error)
                }
            } else {
                this.Msg='Usuario no Existe'
                this.msgbox=true;
                this.color='red';
                this.showSnack();
            }
        },
        async buscarSucursal(){
            const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
            const datos=response.data;
            this.itemsucursal=[];
            //this.itemsucursal.push({"code":"0","name":"Nueva"});
            if (datos[0].existe=='si'){
                datos.forEach(element => {
                    this.itemsucursal.push({"code":element.ID,"name":element.Nombre})        
                });
            }
        },
        openDialog(a) {
            console.log(a);
            if(a==0){
                this.alertaSucursal='';
                this.newSucursal='';
                this.dialogSucursal=true;
                 this.disabledSucursal=false;
                this.filledSucursal=false;
            } else {
                this.alertaSucursal='';
                this.newSucursal='';
                this.dialogSucursal=false;
            }
        },
        async newSucursales(){
            if(this.newSucursal!=''){
                this.disabledSucursal=true;
                this.filledSucursal=true;
                var parametros={
                    'contrato': this.contrato,
                    'nombre': this.newSucursal};
                const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursal/nueva',parametros);
                const datos=response.data;
                if (datos=='si'){
                    // buscar ID de la nueva sucursal
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/sucursales/'+this.contrato);
                    const datos1=response1.data;
                    if (datos1[0].existe=='si'){
                        datos1.forEach(element => {
                            if(element.Nombre==this.newSucursal){
                                this.itemsucursal.push({"code":element.ID,"name":element.Nombre});
                                this.sucursal=element.ID
                            }
                        });
                    } 
                    this.alertaSucursal='si';                
                } else {
                    this.alertaSucursal='no';
                }
            } else {
                this.alertaSucursal='no';
            }
        },

        restoDatos(bool){
            this.disabledDatos=bool,
            this.filledDatos=bool
        },
        showSnack(){
            setTimeout(()=>{this.msgbox=false},3000)
        },
        onlyNumber ($event) {
         //console.log($event.keyCode); //keyCodes value
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // && keyCode !== 46 is dot
                $event.preventDefault();
            }
        },
        clsInput(){
            this.nombre='';
            this.email='';
            this.Permiso_1_1=false;
            this.Permiso_2_1=false;
            this.Permiso_2_2=false;
            this.Permiso_3_1=false;
            this.Permiso_3_2=false;
            this.Permiso_3_3=false;
            this.Permiso_3_4=false;
            this.Permiso_4_1=false;
            this.Permiso_4_2=false;
            this.Permiso_4_3=false;
            this.Permiso_5_1=false;
            this.Permiso_6_1=false;
            this.Permiso_6_2=false;
            this.Permiso_6_3=false;
            this.Permiso_7_1=false;
            this.Permiso_7_2=false;
            this.Permiso_7_3=false;
            this.Permiso_8_1=false;
            this.Permiso_8_2=false;
            this.nPermiso_1_1=0;
            this.nPermiso_2_1=0;
            this.nPermiso_2_2=0;
            this.nPermiso_3_1=0;
            this.nPermiso_3_2=0;
            this.nPermiso_3_3=0;
            this.nPermiso_3_4=0;
            this.nPermiso_4_1=0;
            this.nPermiso_4_2=0;
            this.nPermiso_4_3=0;
            this.nPermiso_5_1=0;
            this.nPermiso_6_1=0;
            this.nPermiso_6_2=0;
            this.nPermiso_6_3=0;
            this.nPermiso_7_1=0;
            this.nPermiso_7_2=0;
            this.nPermiso_7_3=0;
            this.nPermiso_8_1=0;
            this.nPermiso_8_2=0;
            this.Reporte=false;
            this.nReporte=0;
            this.Ecommerce=false;
            this.nEcommerce=0;
            this.Importa=false;
            this.nImporta=0;
            this.alertaSucursal='';
            this.disabledSucursal=false;
            this.filledSucursal=false;
            this.newSucursal='';
            this.dialogSucursal=false;
            this.itemSucursal=[];
            this.sucursal=0;
            this.estado='V';
            this.comiGeneral='';
            this.descGeneral='';
            this.descComision='';
            this.comision_01='';
            this.comision_02='';
            this.comision_03='';
            this.comision_04='';
            this.comision_05='';
            this.comision_06='';
            this.gerente=false;
            this.Integra=false;
            this.nIntegra=0;

        },
        async verUsuario(xUsu){
             this.loading=true;
             this.msgbox=false;
             this.clsInput();
            if(xUsu.trim()!=''){
                this.disabledUsuario=true;
                this.filledUsuario=true;
                const response = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/'+this.contrato+'/'+xUsu);
                const datos=response.data;
                //console.log(datos);
                if (datos[0].existe=='no'){
                    this.existe='no'
                    this.restoDatos(false);
                }
                if (datos[0].existe=='error'){
                    this.Msg='Conexion Inestable, intentelo mas tarde'
                    this.msgbox=true;
                    this.color='red';
                    this.showSnack();
                    this.newUsuario()
                }
                if (datos[0].existe=='si'){               
                    this.existe='si';
                    this.contrato=datos[0].Contrato;
                    this.usuario=datos[0].Usuario;
                    this.nombre=datos[0].Nombre;
                    this.email=datos[0].Email;
                    this.Permiso_1_1=this.BooleanOpc(datos[0].Permiso_1_1);
                    this.Permiso_2_1=this.BooleanOpc(datos[0].Permiso_2_1);
                    this.Permiso_2_2=this.BooleanOpc(datos[0].Permiso_2_2);
                    this.Permiso_3_1=this.BooleanOpc(datos[0].Permiso_3_1);
                    this.Permiso_3_2=this.BooleanOpc(datos[0].Permiso_3_2);
                    this.Permiso_3_3=this.BooleanOpc(datos[0].Permiso_3_3);
                    this.Permiso_3_4=this.BooleanOpc(datos[0].Permiso_3_4);
                    this.Permiso_4_1=this.BooleanOpc(datos[0].Permiso_4_1);
                    this.Permiso_4_2=this.BooleanOpc(datos[0].Permiso_4_2);
                    this.Permiso_4_3=this.BooleanOpc(datos[0].Permiso_4_3);
                    this.Permiso_5_1=this.BooleanOpc(datos[0].Permiso_5_1);
                    this.Permiso_6_1=this.BooleanOpc(datos[0].Permiso_6_1);
                    this.Permiso_6_2=this.BooleanOpc(datos[0].Permiso_6_2);
                    this.Permiso_6_3=this.BooleanOpc(datos[0].Permiso_6_3);
                    this.Permiso_7_1=this.BooleanOpc(datos[0].Permiso_7_1);
                    this.Permiso_7_2=this.BooleanOpc(datos[0].Permiso_7_2);
                    this.Permiso_7_3=this.BooleanOpc(datos[0].Permiso_7_3);
                    this.Permiso_8_1=this.BooleanOpc(datos[0].Permiso_8_1);
                    this.Permiso_8_2=this.BooleanOpc(datos[0].Permiso_8_2);
                    this.Reporte=this.BooleanOpc(datos[0].Reporte);
                    this.Ecommerce=this.BooleanOpc(datos[0].Ecommerce);
                    this.Importa=this.BooleanOpc(datos[0].Importa);
                    this.Integra=this.BooleanOpc(datos[0].Integra);

                    this.sucursal=datos[0].Sucursal;
                    this.estado=datos[0].Estado;
                    this.restoDatos(false);
                    const response1 = await axios.get('https://www.transervi.cl/Api_TranServi/public/api/inv/comisiones/'+this.contrato+'/'+xUsu);
                    const datos1=response1.data;
                    //console.log(datos);
                    if (datos1[0].existe=='si'){
                        this.comiGeneral=datos1[0].Comision;
                        this.descGeneral=datos1[0].DesGeneral;
                        this.descComision=datos1[0].DesComision;
                        this.comision_01=datos1[0].Tarjetas;
                        this.comision_02=datos1[0].Cheque;
                        this.comision_03=datos1[0].ContadoConDes;
                        this.comision_04=datos1[0].CreditoConDes;
                        this.comision_05=datos1[0].ContadoSinDes;
                        this.comision_06=datos1[0].CreditoSinDes;
                        if(datos1[0].Gerente=='si'){ this.gerente=true } 
                        else { this.gerente=false }                        
                    } 
                } 
            } else {
                this.existe=''
            }
            this.loading=false;
        },
        BooleanOpc(nbolean){
            if(nbolean!=1){
                return false
            } else { return true}
        },
        OpcBoolean(bolean){
            if(bolean!=true){
                return 0
            } else { return 1}
        },
        async saveUsuario(){
            this.loading=true;
            this.msgbox=false;
            if (this.contrato!='' && this.usuario!='' &&
                this.nombre!='' && this.email!=''){
                this.nPermiso_1_1=this.OpcBoolean(this.Permiso_1_1);
                this.nPermiso_2_1=this.OpcBoolean(this.Permiso_2_1);
                this.nPermiso_2_2=this.OpcBoolean(this.Permiso_2_2);
                this.nPermiso_3_1=this.OpcBoolean(this.Permiso_3_1);
                this.nPermiso_3_2=this.OpcBoolean(this.Permiso_3_2);
                this.nPermiso_3_3=this.OpcBoolean(this.Permiso_3_3);
                this.nPermiso_3_4=this.OpcBoolean(this.Permiso_3_4);
                this.nPermiso_4_1=this.OpcBoolean(this.Permiso_4_1);
                this.nPermiso_4_2=this.OpcBoolean(this.Permiso_4_2);
                this.nPermiso_4_3=this.OpcBoolean(this.Permiso_4_3);
                this.nPermiso_5_1=this.OpcBoolean(this.Permiso_5_1);
                this.nPermiso_6_1=this.OpcBoolean(this.Permiso_6_1);
                this.nPermiso_6_2=this.OpcBoolean(this.Permiso_6_2);
                this.nPermiso_6_3=this.OpcBoolean(this.Permiso_6_3);
                this.nPermiso_7_1=this.OpcBoolean(this.Permiso_7_1);
                this.nPermiso_7_2=this.OpcBoolean(this.Permiso_7_2);
                this.nPermiso_7_3=this.OpcBoolean(this.Permiso_7_3);
                this.nPermiso_8_1=this.OpcBoolean(this.Permiso_8_1);
                this.nPermiso_8_2=this.OpcBoolean(this.Permiso_8_2);
                this.nReporte=this.OpcBoolean(this.Reporte);
                this.nEcommerce=this.OpcBoolean(this.Ecommerce);
                this.nImporta=this.OpcBoolean(this.Importa);
                this.nIntegra=this.OpcBoolean(this.Integra);
                var parametros={
                    'contrato': this.contrato,
                    'usuario': this.usuario,
                    'nombre': this.nombre,
                    'email': this.email,
                    'comision':this.comision,
                    'permiso_1_1': this.nPermiso_1_1,
                    'permiso_2_1': this.nPermiso_2_1,
                    'permiso_2_2': this.nPermiso_2_2,
                    'permiso_3_1': this.nPermiso_3_1,
                    'permiso_3_2': this.nPermiso_3_2,
                    'permiso_3_3': this.nPermiso_3_3,
                    'permiso_3_4': this.nPermiso_3_4,
                    'permiso_4_1': this.nPermiso_4_1,
                    'permiso_4_2': this.nPermiso_4_2,
                    'permiso_4_3': this.nPermiso_4_3,
                    'permiso_5_1': this.nPermiso_5_1,
                    'permiso_6_1': this.nPermiso_6_1,
                    'permiso_6_2': this.nPermiso_6_2,
                    'permiso_6_3': this.nPermiso_6_3,
                    'permiso_7_1': this.nPermiso_7_1,
                    'permiso_7_2': this.nPermiso_7_2,
                    'permiso_7_3': this.nPermiso_7_3,
                    'permiso_8_1': this.nPermiso_8_1,
                    'permiso_8_2': this.nPermiso_8_2,
                    'reporte': this.nReporte,
                    'ecommerce': this.nEcommerce,
                    'importa': this.nImporta,
                    'integra': this.nIntegra,
                    'sucursal': this.sucursal,
                    'estado': this.estado
                    };
                    
                if (this.existe=='si'){
                    // actualizar
                    const response = await axios.put('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/actualiza/'+this.usuario,parametros);
                    const datos=response.data;
                    //console.log(datos);
                    if (datos=='si'){                
                        this.Msg='Modificacion de Usuario Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        this.newUsuario()
                    }
                }
                if (this.existe=='no'){
                    // ingresar                    
                    const response = await axios.post('https://www.transervi.cl/Api_TranServi/public/api/inv/usuarios/nuevo',parametros);
                    const datos=response.data;
                    if (datos=='si'){ 
                        this.existe='si';               
                        this.Msg='Nuevo Usuario Existoso !!!'
                        this.msgbox=true;
                        this.color='success';
                        this.showSnack();
                    } 
                    if (datos=='error'){
                        this.Msg='Conexion Inestable, intentelo mas tarde'
                        this.msgbox=true;
                        this.color='red';
                        this.showSnack();
                        //this.newUsuario()
                    }
                }
                // if (this.existe==''){
                //     this.Msg='Tiene que validar Usuario'
                //     this.msgbox=true;
                //     this.color='red'
                // }            
            } else {
                    this.Msg='Todos los datos son obligatorios'
                    this.msgbox=true;
                    this.color='info';
                    this.showSnack();
            }
            this.loading=false;
        },
        newUsuario(){
            this.loading=true;
            this.msgbox=false;
            this.usuario='';
            this.clsInput();
            this.disabledUsuario=false;
            this.filledUsuario=false;
            this.existe='';
            this.loading=false;
            this.restoDatos(true);
        },
    }
}
</script>